.Join {
  .iBox:hover .j-item {
    /* transform: translateX(-200px); */
    background: linear-gradient(90deg, #8eaeff 0%, #003bec 100%);
  }

  .iBox:hover .j-item .hoverBg {
    opacity: 1;
  }

  .iBox .j-item .title {
    position: relative;
    z-index: 6;
  }

  .iBox:hover .j-item .title .t-ch,
  .iBox:hover .j-item .title .t-en {
    color: #fff;
  }

  .iBox .j-item .hoverBg {
    opacity: 0;
  }

  .j-item {
    position: relative;
    transition: transform 0.35s;
    background-color: #fff;
    z-index: 9;
    border-radius: 4px;
    box-shadow: 0 0 24px 8px #00000012;
  }

  .more {
    position: absolute;
    background: #284a9f;
    color: #fff;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0 2.5rem 0 3rem;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
  }
}

.xs .Home {
  overflow: hidden;
  .glycomics {
    padding-top: 3rem;
  }
  .product {
    padding-top: 3rem;
    padding-bottom: 0rem;
    .product-card {
      padding: 0;
      margin-bottom: 3rem;
    }
  }

  .who {
    padding-bottom: 4rem;
    .cate {
      position: relative;
    }
  }
}

.job .dot {
  width: 6px;
  height: 6px;
  background: #000;
  display: inline-block;
  margin-right: 12px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-top: 12px;
}
