.contact-modal .ant-form-item-label > label {
  height: 40px;
  text-align: left;
  /* width: 80px; */
}

.contact-modal .ant-form-item-control-input {
  text-align: center;
}

.contact-modal .ant-form-item-with-help .ant-form-item-explain {
  min-height: 32px;
}

.contact-modal .ant-modal-header {
  border-bottom: none;
  padding: 24px 24px 0;
}

.contact-modal .ant-modal-title {
  display: flex;
}
.contact-modal .ant-modal-title:before {
  content: "";
  width: 6px;
  height: 24px;
  background: #4878f0;
  display: inline-block;
  margin-right: 10px;
}
