.xs.Product .ProductG .product-thumb,
.xs.Product .ProductJ .product-thumb,
.xs.Product .Estimate .product-thumb {
  position: relative;
  top: auto;
}

.xs.Product .ProductG .info,
.xs.Product .ProductJ .info,
.xs.Product .Estimate .info {
  height: auto;
}

.xs.Product .product-nav .item {
  padding: 1rem 0;
  font-weight: 600;
  font-size: 1rem;
}
