.Product {
  .item {
    position: relative;
    .text-content {
      position: absolute;
      transition: height 0.5s;
    }
    &:hover .text-content {
      height: 188px;
    }
  }
}
