.xs.Company {
  .info {
    width: auto;
  }
  .boss {
    padding: 1rem 0;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
  .avatar {
    position: relative;
    top: auto;
  }
}

.xs.Result .cirrhosis {
  padding: 2.5rem 0;
  margin-top: 2rem;
}

.xs.Result .curve .thumb {
  position: relative;
  top: auto;
  right: auto;
}

.xs.Result .curve {
  padding-top: 3rem;
}

.xs.Result .glycan {
  padding-bottom: 2rem;
}

.xs.Result .humans {
  padding: 2.5rem 0;
  margin-top: 2rem;
}

.xs.Result .humans .thumb {
  position: relative;
  top: auto;
  right: auto;
}

.xs.Result .mark {
  margin-top: 1rem;
  flex-direction: column-reverse;
  .thumb {
    position: relative;
    top: auto;
    right: auto;
  }
}

.xs.Result .mark .thumb {
  position: relative;
  top: auto;
  right: auto;
}

.xs.Result .consensus,
.xs.Result .summery {
  padding-top: 2rem;
}
.xs.Mission .reverse {
  flex-direction: column-reverse;
}

.team-people .item {
  position: relative;
}

.team-people .item .user-info {
  position: absolute;
  bottom: 2.25rem;
  background: #fff;
  padding: 0.75rem 2rem;
  width: 84%;
  margin: 0 7%;
}

.team-people .item .user-info.color {
  background: #4878f0;
  color: #fff;
}
