.xs .Home {
  overflow: hidden;
  .glycomics {
    padding-top: 3rem;
  }
  .product {
    padding-top: 3rem;
    padding-bottom: 0rem;
    .product-card {
      padding: 0;
      margin-bottom: 3rem;
    }
  }

  .who {
    padding-bottom: 4rem;
    .cate {
      position: relative;
    }
  }
}
