.Home {
  .p-thumb:hover {
    .btn {
      opacity: 1;
    }
  }

  .NewsCard {
    .card {
      position: absolute;
      bottom: 0;
      width: 100%;
      color: white;
      font-size: 1.25rem;
      background: #2959d3bf;
      .month {
        font-size: 3rem;
        margin-right: 1rem;
        margin-left: 1.5rem;
      }
      .Date {
        margin-right: 1rem;
      }
    }
  }
}

.xs .Home {
  overflow: hidden;
  .glycomics {
    padding-top: 3rem;
  }
  .product {
    padding-top: 3rem;
    padding-bottom: 0rem;
    .product-card {
      padding: 0;
      margin-bottom: 3rem;
    }
  }

  .who {
    padding-bottom: 4rem;
    .cate {
      position: relative;
    }
  }
}
